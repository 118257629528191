#notificationsContainer {
    position: fixed;
    top: 10rem;
    left: 2.4rem;
    z-index: 5;

    .window-toggle {
        background: transparent;
        border: none;
        outline: none;
        display: flex;
        align-items: center;
        gap: 1rem;

        .circle {
            @include font-style(LatoBlack, 1.6rem, #ffffff);
            @include border-radius(50%);
            display: flex;
            justify-content: center;
            align-items: center;
            background: #ffffff;
            height: 4.4rem;
            width: 4.4rem;

            img {
                object-fit: contain;
                height: 2.4rem;
                padding: 0.1rem;
            }

            &.blue {
                background: $border_btn_blue;
                .counter {
                    height: 2rem;
                }
            }

            &.animate {
                animation: pulse 2s infinite;
            }
        }
    }

    .window {
        position: absolute;
        top: 0;
        width: 36rem;
        height: calc(100vh - 300px);

        .window-body {
            .empty {
                @include font-style(LatoBlack, 1.6rem, $almost_black);
            }

            .notification {
                @include border-radius(6px);
                border: 2px solid #ffffff;
                display: flex;
                justify-content: space-between;
                padding: 1rem 2.4rem;
                background: #f7fafd;

                .notification-wrapper {
                    display: flex;
                    flex-direction: column;
                    gap: 0.75rem;

                    .notification-date {
                        @include font-style(LatoRegular, 1.4rem, #1d1d1b);
                    }

                    .notification-title {
                        @include font-style(LatoBold, 1.6rem, #0164c7);
                    }

                    .notification-content {
                        @include font-style(LatoRegular, 1.4rem, #1d1d1b);
                    }
                }

                .mark-as-read {
                    border: none;
                    outline: none;
                    background: transparent;
                    color: $hover_btn_txt;
                    font-size: 1.6rem;

                    &:hover {
                        color: $err_red;
                    }
                }
            }
        }
    }

    &:hover {
        .window-toggle .circle.animate {
            animation: none;
        }

        .window {
            display: flex;
        }
    }
}
