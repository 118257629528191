@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    75% {
        transform: rotate(1080deg);
        animation-timing-function: linear;
    }
    100% {
        transform: rotate(1170deg);
        animation-timing-function: ease-in-out;
    }
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba($border_btn_blue, 0.25);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 8px rgba($border_btn_blue, 0.5);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba($border_btn_blue, 0.1);
    }
}
