@import '_variables';

.window {
    border: 2px solid #ffffff;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-color: $bg_grey;
    display: none;
    min-width: 33rem;
    max-width: 99rem;
    max-height: calc(100vh - 30rem);
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 3;

    &.window-open {
        display: flex;
    }

    &.window-draggable {
        box-shadow: 0 12px 33px #1b9ad64d;
        position: absolute;
    }

    &.window-fixed-footer {
        position: absolute;
        top: auto;
        bottom: 13rem;
        box-shadow: 0 12px 33px #1b9ad64d;
        z-index: 4;
    }

    .window-header {
        @include font-style(LatoBold, 1.6rem, $almost_black);
        display: flex;
        align-items: center;
        border: 1px solid #ffffff;
        background-color: $light_green;
        margin: -2px 0 0 -2px;
        width: calc(100% + 4px);
        padding-left: 1.5rem;

        .window-icon {
            max-height: 24px;
            width: 24px;
            margin-right: 2rem;
            filter: brightness(0%);
        }

        .window-header-actions {
            display: flex;
            margin-left: auto;
            gap: 0.4rem;

            .window-popup,
            .window-close {
                @include border-radius(2px);
                background-color: rgba($green, 0.25);
                border: none;
                width: 3.6rem;
                height: 3.6rem;
                background-size: contain;
                font-size: 0.8rem;

                &:hover {
                    background-color: rgba($green, 0.6);
                }

                .icon-popup {
                    display: inline-block;
                    background: url(../images/popup.svg) center no-repeat;
                    width: 14px;
                    height: 14px;
                    background-size: contain;
                }
            }
        }

        .window-close {
        }
    }

    .window-body {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1.5rem;
        overflow: auto;

        &.window-body-alt {
            background-color: #ffffff;
            border: 1px solid $footer;
        }

        label {
            @include font-style(LatoItalic, 1.4rem, $almost_black);
            background-color: $bg_grey_2;
            padding: 1rem 1.5rem;
        }

        textarea {
            @extend %input-style;
            resize: none;
        }
    }

    .window-footer {
        padding-bottom: 2.5rem;
    }
}
