@import 'variables';
@import 'mixins';

.disassembly-slide-container {
    display: flex;
    gap: 4rem;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 5rem;
    height: 100%;

    .slide-contents {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .photo-layout-root {
            border: 1px solid $footer;
            background-color: #ffffff;
            padding: 0;

            .layout-photos {
                .layout {
                    background-color: $footer;
                    border: none;
                    grid-gap: 1px;

                    .slot {
                        background-color: #ffffff;
                        padding: 4px;
                        img {
                            cursor: auto;
                        }
                    }
                }
            }
        }

        .banner {
            @include font-style(LatoBold, 2rem, #ffffff);
            width: 100%;
            max-width: 1420px;
            text-transform: uppercase;
            border: 1px solid $footer;
            padding: 1.8rem 0;
            text-align: center;

            &.I {
                background-color: $hover_btn_txt;
            }

            &.W {
                background-color: $border_err_red;
                border-color: #ffffff;
            }

            &.A {
                background-color: $bg_warning;
                border-color: #ffffff;
                color: $almost_black;
            }
        }

        .banner-placeholder {
            width: 100%;
            max-width: 1420px;
            padding: 3.1rem 0;
        }
    }

    .actions-contents {
        flex-grow: 1;

        .disassembly-wrapper {
            margin-top: 0;
        }
    }
}

#disassemblySidePanel {
    width: 370px;
    border: 4px solid #e5ecad;
    border-radius: 34px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    padding: 1.8rem 1.5rem;
    height: 100%;

    .header {
        @include font-style(LatoBold, 1.6rem, $almost_black);
        margin-bottom: 3rem;
    }

    .disassembly-parts {
        display: flex;
        flex-direction: column;
        gap: 2.1rem;
        padding-bottom: 1rem;
        border-bottom: 3px solid rgba($bg-grey_2, 0.3);

        width: 100%;
        height: 100%;
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
            width: 0 !important;
        }

        .disassembly-part {
            width: 100%;
            cursor: pointer;

            small {
                display: flex;
                justify-content: space-between;
                .counter {
                    @include font-style(LatoBold, 1rem, $almost_black);
                }

                .status {
                    @include font-style(LatoRegular, 1rem, $almost_black);
                    text-transform: uppercase;
                }
            }

            .part-input {
                @include font-style(LatoBold, 1.4rem, $almost_black);
                background-color: $bg_grey;
                position: relative;
                border: 2px solid $bg_grey;
                padding: 1.3rem;
                display: flex;
                align-items: center;
                user-select: none;

                .disassembly-checkbox {
                    width: 30px;
                    height: 30px;
                    margin: 0 1.8rem 0 0;
                    border: 2px solid $hover_btn_txt;
                    pointer-events: none;
                    border-radius: 4px;
                    appearance: none;
                    -webkit-appearance: none;
                    background: url(../images/checkmark.svg) center no-repeat
                        #ffffff;
                    background-size: 14px;

                    &:checked {
                        background-color: $hover-btn_txt;
                    }
                }

                .right-side {
                    margin-left: auto;
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;

                    .part-comments-btn {
                        background: url(../images/comments.svg) center no-repeat;
                        border: 2px solid #ffffff;
                        border-radius: 50%;
                        background-color: $footer;
                        outline: none;
                        height: 27px;
                        width: 27px;
                        margin-top: 1px;
                        overflow: visible;

                        .comments-wrapper {
                            display: none;
                            position: absolute;
                            z-index: 10;
                            left: 0;
                            right: 0;
                            top: 40px;
                            background-color: $light-green;
                            border: 3px solid #ffffff;
                            padding: 0.9rem;
                            border-radius: 9px;
                            box-shadow: 0 3px 6px #185acb30;

                            .part-comments {
                                width: 100%;
                                padding: 1.2rem;
                                resize: none;
                                outline: none;
                                border: 1px solid $footer;
                                border-radius: 4px;
                                height: 18rem;

                                &::placeholder {
                                    @include font-style(
                                        LatoBlackItalic,
                                        1.4rem,
                                        rgba($almost_black, 0.3)
                                    );
                                }

                                &:focus {
                                    border: 3px solid $hover-btn_txt;
                                }
                            }
                        }

                        &:focus,
                        &:focus-within,
                        &:hover {
                            background-color: $hover_btn_txt;
                            border-color: $hover_btn_txt;
                            outline: 6px solid rgba($hover_btn_txt, 0.12);

                            .comments-wrapper {
                                display: block;
                            }
                        }
                    }

                    .status-icon {
                        height: 27px;
                        width: 27px;
                    }
                }
            }

            &.N {
                small .status {
                    @include font-style(LatoBoldItalic, 1rem, $almost_black);
                    opacity: 0.5;
                }

                &:hover {
                    .status-icon {
                        background: $bg-grey_2;
                        border: 2px solid #ffffff;
                        border-radius: 50%;
                    }
                }
            }

            &.D {
                .part-input {
                    border-color: $green_2;
                }

                .status-icon {
                    background: url(../images/status-ok.svg) center no-repeat;
                }
            }

            &.C {
                .part-input {
                    border-color: #f2ce1c;
                    background-color: #f2ce1c0d;
                }

                .status-icon {
                    background: url(../images/status-warning.svg) center
                        no-repeat;
                }
            }

            &.G {
                small {
                    .status,
                    .counter {
                        @include font-style(LatoBold, 1rem, $border_err_red);
                    }
                }

                .part-input {
                    border-color: $border_err_red;
                    background-color: #e34f200d;
                }

                .status-icon {
                    background: url(../images/status-danger.svg) center
                        no-repeat;
                }
            }
        }
    }

    .side-panel-footer {
        margin-top: auto;
        width: 100%;

        .device-card {
            @include font-style(LatoBold, 1.4rem, $hover_btn_txt);
            background-color: rgba($bg-grey, 0.3);
            width: 100%;
            height: 6rem;
            border-radius: 0 0 22px 22px;
            border: none;
            outline: none;
            display: flex;
            align-items: center;
            gap: 1rem;
            justify-content: center;

            .icon-card {
                display: inline-block;
                height: 27px;
                width: 27px;
                background: url(../images/document.svg) left center no-repeat;
                background-size: 27px 27px;
            }

            &:hover {
                background-color: #cef5ff;
            }
        }
    }
}

.disassembly-wrapper {
    border: 1px solid $footer;
    max-width: 1420px;
    min-height: calc(100vh - 510px);
    padding: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 6rem auto 0 auto;

    &.red {
        border-color: $border_err_red;
    }

    h1,
    h2 {
        @include font-style(LatoBold, 1rem, $almost_black);
        display: inline-block;
        text-transform: uppercase;
        margin-bottom: 5px;
    }
}

.disassembly-actions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-width: 730px;

    .disassembly-box {
        @include font-style(LatoRegular, 1.4rem, $almost_black);
        padding: 0.6rem 0.6rem 0.6rem 2.2rem;
        border-radius: 4px;
        background-color: #ffffff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 60px;

        .disassembly-action {
            @include font-style(LatoBold, 1.4rem, $border_btn_blue);
            width: 179px;
            padding: 0.5rem 0;
            border-radius: 4px;
            background-color: #ffffff;
            border: 2px solid $border_btn_blue;

            &.confirmed {
                color: $almost_black;
                background-color: $green;
                border: 2px solid $green;
            }

            &:hover {
                color: $hover_btn_txt;
                background-color: $hover_btn_menu;
                border-color: $hover_btn_txt;
            }
        }

        .placeholder {
            width: 179px;
            height: 100%;
            border-radius: 4px;
            background-color: $bg_grey;
        }
    }
}

.disassembly-footer {
    @include flex-space-between;
    @include border-radius(4px);
    position: fixed;
    display: flex;
    left: 2.2rem;
    bottom: 5rem;
    width: calc(100% - 4.4rem);
    height: 110px;
    padding: 0 2.7rem;
    z-index: 2;
    background-color: $bg_warning;

    .footer-button {
        @include font-style(LatoBold, 1.2rem, #fff);
        @include border-radius(20px);
        background-color: transparent;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 1rem;
        align-items: center;
        padding: 1rem 1rem 0.5rem 1rem;
        border: 2px solid rgba($bg_warning, 0.3);
        aspect-ratio: 1;
        width: 11.3rem;
        text-transform: uppercase;

        img {
            height: 32px;
            max-width: 32px;
        }

        &:hover {
            background-color: rgba($footer, 0.6);
            border-color: transparent;
        }

        &.disabled {
            opacity: 30%;
            pointer-events: none;
        }
    }

    .disassembly-comments {
        display: flex;
        padding: 1rem 1.7rem 1rem 0;
        height: 100%;

        .footer-button {
            color: $almost_black;
            border-color: rgba($almost_black, 0.3);
            gap: 0.5rem;

            &:hover {
                border-color: transparent;
            }
        }
    }

    .middle {
        display: flex;
        position: relative;
        justify-content: space-between;
        background-color: $almost_black;
        flex-grow: 1;
        border: 2px solid $bg_warning;
        height: 100%;
        padding: 1rem 5rem;

        .middle-left {
            display: flex;
            gap: 1.6rem;
        }

        .middle-center {
            display: flex;

            .footer-button {
                gap: 0.5rem;
            }

            .window {
                width: 64rem;
            }
        }

        .middle-right {
            display: flex;
            gap: 1.6rem;
        }
    }

    .navigation {
        display: flex;
        padding: 1rem 0 1rem 1.7rem;
        height: 100%;
        gap: 1rem;

        .footer-button {
            color: $almost_black;
            border-color: rgba($almost_black, 0.3);
            gap: 0.5rem;

            &:hover {
                border-color: transparent;
            }
        }

        .btn-next {
            @include font-style(LatoBold, 1.4rem, $almost_black);
            flex-direction: row;
            justify-content: space-between;
            aspect-ratio: auto;
            width: auto;
            text-decoration: none;
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            padding: 1rem 4rem 1rem 4rem;
            gap: 2rem;
            min-width: 20rem;

            &:hover {
                background-color: $hover_btn_txt;
                color: #ffffff;

                .icon-arrow_right {
                    font-size: 38px;
                    color: #ffffff;
                }
            }

            .icon-arrow_right {
                font-size: 38px;
                color: $hover_btn_txt;
            }

            &.disabled {
                span {
                    visibility: hidden;
                }

                .icon-arrow_right {
                    opacity: 10%;
                }
            }
        }

        .slide-navigation {
            display: flex;
            align-items: center;
            gap: -1rem;
            a {
                border: 2px solid #b5ba5f;
                padding: 2.4rem 3.2rem;

                &.left {
                    border-radius: 20px 0 0 20px;
                    margin-right: -3.05rem;
                    padding-right: 4.4rem;
                }

                &.right {
                    border-radius: 0 20px 20px 0;
                    margin-left: -3.05rem;
                    padding-left: 4.4rem;
                }

                &:hover {
                    background-color: $hover_btn_txt;

                    i {
                        &.arrow-left,
                        &.arrow-right {
                            background-color: #ffffff;
                        }
                    }
                }

                i {
                    display: block;
                    width: 24px;
                    height: 39px;

                    &.arrow-left {
                        mask-image: url(../images/arrow-left.svg);
                        mask-size: contain;
                        background-color: $hover_btn_txt;
                    }

                    &.arrow-right {
                        mask-image: url(../images/arrow-right.svg);
                        mask-size: contain;
                        background-color: $hover_btn_txt;
                    }
                }
            }

            .slide-number {
                @include font-style(LatoBlackItalic, 2rem, $almost_black);
                border: 2px solid #b5ba5f;
                border-radius: 50%;
                padding: 1.25rem;
                z-index: 2;
                background-color: $bg_warning;
            }
        }

        .btn-finish {
            aspect-ratio: auto;
            width: auto;
            max-width: 28rem;
            height: 100%;
            padding: 1rem 4rem;
            text-align: center;
            flex-direction: row;
        }
    }
}

.actions-banner {
    @include font-style(LatoBoldItalic, 2rem, #ffffff);
    background-color: $green_2;
    border: 1px solid $footer;
    width: 100%;
    max-width: 1420px;
    padding: 1.7rem 0;
    text-align: center;
    margin: 1rem auto 0 auto;

    &.red {
        background-color: $border_err_red;
        border-color: #ffffff;
    }

    &.disabled {
        visibility: hidden;
    }
}

.device-identification {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 1rem;
    p {
        @include font-style(LatoBold, 1rem, $almost_black);
        margin-right: 55rem;
    }

    .device-id {
        @include font-style(LatoRegular, 2rem, $almost_black);
        background-color: #ffffff;
        padding: 2rem;
        border-radius: 4px;
        width: 730px;
        b {
            @include font-style(LatoBold, rem, $almost_black);
        }
    }
}

.specification-container {
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    max-width: 1600px;
    margin: 3.5rem auto 0 auto;
    background-color: #ffffff;

    .table-wrapper {
        @include font-style(LatoRegular, 1.2rem, $almost_black);
        padding: 2.4rem 1.9rem;
        border: 1px solid $footer;
        max-height: 620px;
        overflow-y: auto;

        .specification-table {
            width: 100%;
            th {
                text-align: left;
                padding: 1rem 0 1rem 2rem;
            }
            td {
                text-align: left;
                padding: 1rem 2rem 1rem 0;
            }
            tr:nth-child(even) {
                background-color: rgba($bg-grey, 0.9);
            }
        }
    }

    .photo-layout-root {
        grid-column: 2;
        grid-row: 1;
        padding: 2.4rem 1.9rem;
        border: 1px solid $footer;

        .layout-photos {
            .layout {
                border: none;
                background-color: #ffffff;
                width: 100%;
                height: 572px;

                .slot {
                    background-color: #ffffff;
                    img {
                        cursor: auto;
                    }
                }
            }
        }
    }
}

.finished-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin: 0;
    h1 {
        @include font-style(LatoBold, 2rem, $almost_black);
        text-transform: none;
    }

    .finished-icon-container {
        background-color: #ffffff;
        border-radius: 68px;
        padding: 3.5rem 16.5rem;
        .finished-icon {
            display: block;
            border-radius: 50%;
            background: url(../images/checkmark.svg) center no-repeat $green;
            width: 14rem;
            height: 14rem;
        }
    }
}

.preview {
    border: 12px solid $almost_black;
    position: absolute;
    z-index: 20000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    .accent-border {
        width: 100%;
        height: 100%;
        border: 2px solid $bg_warning;

        .preview-text {
            @include font-style(LatoBold, 20rem, rgba($almost_black, 0.05));
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            height: 100%;
            width: 100%;
            transform: matrix(0.94, 0.34, -0.34, 0.94, 0, 0);
        }

        .page-container {
            #top-header {
                top: 14px;
                left: 14px;
                right: 14px;
                width: auto;
            }

            footer {
                left: 14px;
                right: 14px;
                bottom: 14px;
                width: auto;
            }

            .disassembly-footer {
                left: 3.6rem;
                width: calc(100% - 7.2rem);
                bottom: 6.4rem;
            }

            .photo-layout-root {
                position: inherit;
            }
        }
    }

    .preview-blocker {
        position: absolute;
        top: 70px;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1000;
    }
}

.disassembly-content-wrapper {
    display: flex;
    justify-content: center;
    height: calc(100vh - 300px);
    padding: 0 2rem;
    width: 100%;
    gap: 1rem;

    .window-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        height: calc(100% - 6rem);
        margin-top: 6rem;
        align-self: flex-start;
        z-index: 2;

        .window {
            width: 36rem;
        }
    }
}
