@import 'variables';
@import 'typography';
@import 'mixins';
@import 'animations';

.swal2-modal {
    @include border-radius(12px);
    border: 3px solid #ffffff;
    padding: 4rem 2rem;

    .swal2-title {
        @include font-style(LatoRegular, 2.5rem, $almost_black);
        text-transform: uppercase;
        margin-bottom: 25px;
    }

    .swal2-input {
        @extend %input-style;
        width: auto;
        background-color: #fff;
    }

    .swal2-html-container {
        @include font-style(LatoRegular, 2rem, $almost_black);
        @include border-radius(12px);
        background: #fff;
        padding: 60px 56px;
    }

    .swal2-actions {
        @include flex-align-end;
        gap: 20px;
        width: 100%;
        margin-top: 35px;

        .btn-submit {
            @include flex-full-centered-items;
            @include font-style(LatoBold, 1.5rem, #fff);
            @include border-radius(4px);
            background: $border_btn_blue;
            height: 48px;
            padding: 0 60px;
            border: 0;
            text-transform: uppercase;

            &:hover {
                background: $hover_btn_txt;
            }
        }

        .btn-secondary {
            @include flex-full-centered-items;
            @include font-style(LatoBold, 1.5rem, $hover_btn_txt);
            @include border-radius(4px);
            background: none;
            height: 48px;
            padding: 0 36px;
            border: 0;
            text-transform: uppercase;

            &:hover {
                background: $hover_btn_menu;
            }
        }
    }
}

.scanner-modal {
    .swal2-modal {
        padding-bottom: 8rem;
        .scanner-container {
            background-color: transparent;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: 3rem;

            .title {
                @include font-style(LatoBold, 3rem, $almost_black);
            }

            .icon {
                display: block;
                width: 10rem;
                height: 10rem;
                margin: 3rem auto;

                &.loader {
                    background: url(../images/loader.svg) center no-repeat;
                    animation: spinner 5s infinite linear;
                }

                &.warning {
                    background: url(../images/warning.svg) center no-repeat;
                    background-size: contain;
                }
            }

            .code-input {
                display: block;
                width: 100%;
                max-width: 70rem;
                margin: 3rem auto 0 auto;
                padding: 2rem 1.5rem;
                border: 2px solid $green_2;
                outline: none;

                &:focus {
                    border-color: $hover_btn_txt;
                }

                &.error {
                    border-color: $border_err_red;
                }
            }
        }

        .swal2-actions {
            max-width: 70rem;
            margin-top: 0;
        }
    }
}
